import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ItemList from './itemList';
import BillingInformation from 'privatePages/Checkout/UserInfo/BillingInformation';
import StripeInfo from 'privatePages/Checkout/StripeInfo';
import { getEventDateAverage } from 'shared/helpers/auctions';
import { amountFormatted } from 'shared/helpers/Currencies';
import './step.scss';

const Bidder = ({
  setFieldValue,
  values,
  cost,
  costBreakdown,
  setStripeInvalid,
  handleDoneClick,
}) => {
  const billingFieldsNames = {
    firstName: 'firstName0',
    lastName: 'lastName0',
    addressName1: 'aboutYouAddress01',
    addressName2: 'aboutYouAddress02',
    city: 'city0',
    state: 'state0',
    postalCode: 'zipcode0',
    country: 'country0',
  };

  const totalCost = cost;
  const stripeRef = useRef(null);

  return (
    <>
      <h1 className="heading payment-headline">
        Complete Your In-Person Bidder Registration
      </h1>
      <div className="payment-page-wrapper">
        <div className="payment-form-wrapper">
          <div className="section">
            <h2 className="sub-heading mt-0">Your Billing Address</h2>
          </div>

          <BillingInformation
            country={values.country0}
            state={values.state0}
            setFieldValue={setFieldValue}
            removeTitle={true}
            removeEmail={true}
            fieldsNames={billingFieldsNames}
            handleDoneClick={handleDoneClick}
            stripeRef={stripeRef}
          />
          <div className={totalCost === 0 ? 'disablePayment' : ''}>
            <StripeInfo
              setStripeValidations={value => setStripeInvalid(value)}
              handleDoneClick={handleDoneClick}
              stripeRef={stripeRef}
            />
          </div>
        </div>
        <div className="payment-cart-wrapper">
          <div className="cart-section">
            <h1 className="heading">YOUR CART</h1>
            <div className="pb-2 bottom-bdr">
              {costBreakdown?.length > 1 ? (
                <p className="count-item">
                  {costBreakdown?.length} Items in your cart
                </p>
              ) : (
                <p className="count-item">
                  {costBreakdown?.length} Item in your cart
                </p>
              )}
              {costBreakdown.length > 0 &&
                costBreakdown.map((item, index) => {
                  return (
                    <ItemList
                      key={index}
                      data={{
                        subTitle: item
                          .map(subItem => subItem.auctionName)
                          .join(' & '),
                        date: getEventDateAverage(
                          item.map(item => item.subEvents).flat()
                        ),
                        cost:
                          item[0].price !== 0
                            ? amountFormatted(item[0].price)
                            : '$0',
                      }}
                    />
                  );
                })}
            </div>
            <div className="pt-2">
              <div className="sec pt-3 order heading4">
                <span>ORDER TOTAL</span>
                <span>
                  {totalCost !== 0 ? amountFormatted(totalCost) : '$0'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Bidder.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.shape({
    formValue: PropTypes.string,
  }),
};

export default Bidder;
