import React from 'react';
import _ from 'lodash';
import GridItem from './GridItem';
import {
  ONLINE_ONLY_AUCTION,
  ONLINE_TELEPHONE_AUCTION,
  ALL_AUCTION,
  BIDDING_METHODS,
} from 'src/utils/auctionTypes';
import styles from './SelectCard.module.scss';
import { checkCustomerTypeOffer } from '../register-to-bid.utils';
import { useAuthenticatedQuery } from 'services/graphql/hooks';
import { GET_USER_CAMPAIGNS } from 'services/graphql/queries/profile';
import { GET_USER_CAMPAIGNS_BY_ID } from 'services/graphql/queries/register-to-bid/kiosk';
import Loading from '../../../components/Spinner';

export default props => {
  const {
    visible,
    values,
    auctions,
    biddingMethod,
    buyerStatus,
    bidderSalesforceId,
    isKiosk,
    length,
  } = props;
  const { loading: isLoading, data: userCampaigns } = isKiosk
    ? useAuthenticatedQuery(GET_USER_CAMPAIGNS_BY_ID, {
        variables: { sfId: bidderSalesforceId },
      })
    : useAuthenticatedQuery(GET_USER_CAMPAIGNS, {});

  return (
    <>
      {isLoading && <Loading />}
      <div className={`select-auction ${!visible ? 'hidden' : ''}`}>
        {/* <h2 className="sub-heading">Select Auctions</h2> */}
        <div className={styles.auctionSelectionHeading}>Select Auctions</div>
        <div className={styles.gridContainer}>
          {auctions.map((auction, index) => {
            return (
              <GridItem
                {...props}
                key={index}
                selected={
                  _.filter(values, o => o === auction.id).length
                    ? auction.id
                    : -1
                }
                data={auction}
                visible={isVisible(
                  biddingMethod,
                  auction.eventAttendanceMode,
                  auction.offers,
                  buyerStatus
                )}
                biddingMethod={biddingMethod}
                isRegistered={userCampaigns?.[
                  isKiosk ? 'userRegisteredCampaignsById' : 'userCampaigns'
                ]?.registeredCampaigns?.some(
                  campaign => campaign?.campaignId === auction?.campaignId
                )}
                length={length}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

const isVisible = (biddingMethod, eventAttendanceMode, offers, buyerStatus) => {
  const ModesToMethodsMap = {
    [ONLINE_ONLY_AUCTION]: [BIDDING_METHODS.ONLINE_BIDDING],
    [ONLINE_TELEPHONE_AUCTION]: [
      BIDDING_METHODS.ONLINE_BIDDING,
      BIDDING_METHODS.TELEPHONE_BIDDING,
    ],
    [ALL_AUCTION]: [
      BIDDING_METHODS.ONLINE_BIDDING,
      BIDDING_METHODS.TELEPHONE_BIDDING,
      BIDDING_METHODS.IN_PERSON_BIDDING,
    ],
  };

  //remove in person bidding method if aucion has no offers
  if (!checkCustomerTypeOffer(offers, buyerStatus))
    ModesToMethodsMap[ALL_AUCTION].pop();
  return ModesToMethodsMap[eventAttendanceMode]?.includes(biddingMethod);
};
