import * as React from 'react';
import styles from './stepper.module.scss';
import {
  activeSignedStepperSteps,
  stepperStep,
  stepperSteps,
  steps,
} from 'utils/registrationStepsConstants';
import { BIDDING_METHODS } from 'utils/auctionTypes';
import Check from '../../../static/svg/check';
import BiddingMethod from 'privatePages/OnlineRegistration/AuctionSelectionStep/BiddingMethod';
export const Stepper = ({
  activeStep,
  prevSteps,
  isPersonalBidding,
  biddingMethod,
  activeSignedTermsAndConditions,
}) => {
  const usedSteps = activeSignedTermsAndConditions
    ? activeSignedStepperSteps
    : stepperSteps;
  const checkSteps = step => {
    if (step === steps.FINANCIAL_DATA) {
      if (isPersonalBidding) {
        return true;
      } else return false;
    } else return true;
  };
  const renderStepper = () => {
    return usedSteps.map((step, index) => {
      return step !== steps.PAYMENT && checkSteps(step) ? (
        // ((step === steps.PAYMENT &&
        //   isPersonalBidding &&
        //   activeStep !== steps.AUCTIONS_SELECTION) ||
        //   step !== steps.PAYMENT)
        //   &&
        <>
          <div className={styles.stepperStepContainer}>
            <div
              className={`${styles.stepperStep} ${
                prevSteps.includes(step) || activeStep === step
                  ? styles.active
                  : ''
              }`}
            >
              {prevSteps.includes(step) && activeStep !== step ? (
                <Check />
              ) : (
                `0${index + 1}`
              )}
            </div>
            <p
              className={`${
                prevSteps.includes(step) || activeStep === step
                  ? styles.activeText
                  : ''
              }`}
            >
              {stepperStep[step]}
            </p>
          </div>
          <hr
            className={`${styles.stepperTrail} ${
              prevSteps.includes(step) || activeStep === step
                ? styles.active
                : ''
            }`}
          />
        </>
      ) : (
        (isPersonalBidding || step === steps.FINANCIAL_DATA) && (
          <div className={styles.stepperStepContainer}>
            <div
              className={`${styles.stepperStep} ${
                prevSteps.includes(step) || activeStep === step
                  ? styles.active
                  : ''
              }`}
            >
              {`0${index + 1}`}
            </div>
            <p
              className={` ${
                prevSteps.includes(step) || activeStep === step
                  ? styles.activeText
                  : ''
              }`}
            >
              {step === steps.PAYMENT ? biddingMethod : stepperStep[step]}
            </p>
          </div>
        )
      );
    });
  };
  return <div className={styles.stepperContainer}>{renderStepper()}</div>;
};
