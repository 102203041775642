import React, { useState, useEffect } from 'react';
import Bidder from './Bidder';
import _ from 'lodash';
import Footer from '../Footer';
import { computePrice } from '../checkout';
import { steps } from 'utils/registrationStepsConstants';

export default ({
  setFieldValue,
  values,
  auctions,
  setStep,
  isValid,
  handleSubmit,
  dispatchChevronStep,
  dispatchPrevSteps,
  cost,
  setCost,
  setStripeInvalid,
  isPaymentValid,
  handleDoneClick,
  nextButtonRef,
  checkOut,
  isKiosk,
  setSuccessfulPayment,
}) => {
  const [costBreakdown, setCostBreakdown] = useState([]);

  useEffect(() => {
    const { price, pricesBreakdown } = computePrice(values, auctions);
    setCost(price);
    setCostBreakdown(pricesBreakdown);
  }, [values.auction]);

  async function submit(values, isValid) {
    await transitionToNextStep(values, isValid);
  }

  async function transitionToNextStep(values, isValid) {
    // const checkedOut = !isKiosk ? await checkOut(values, isValid) : true;
    // setSuccessfulPayment(checkedOut);
    // if (checkedOut !== false) {
    //   dispatchChevronStep('next');
    //   dispatchPrevSteps({ type: 'push', payload: steps.PAYMENT });
    //   setStep(steps.FINANCIAL_DATA);
    // }
    await handleSubmit(values, isValid);
  }

  async function transitionToPrevStep() {
    const prevStep = dispatchPrevSteps({ type: 'pop' });
    setStep(prevStep);
    dispatchChevronStep('back');
  }

  return (
    <>
      <div className="user-input-form step-6">
        <Bidder
          setFieldValue={setFieldValue}
          auctions={auctions}
          values={values}
          setStripeInvalid={setStripeInvalid}
          cost={cost}
          costBreakdown={costBreakdown}
          nextButtonRef={nextButtonRef}
          handleDoneClick={handleDoneClick}
        ></Bidder>
      </div>
      <Footer
        handleNext={() => submit(values, isValid)}
        handleBack={() => transitionToPrevStep()}
        isValid={isPaymentValid(isValid)}
        submitButtonText={'Checkout and Submit Registration'}
        nextRef={nextButtonRef}
      />
    </>
  );
};
