import React from 'react';
import Button from 'components/Button';
import TextInput from 'components/form-elements/TextInput';
import Select from 'components/form-elements/Select';
import DatePicker from 'components/form-elements/DatePicker';
import PhoneNumber from 'components/form-elements/PhoneNumber';
import { useCountriesStates } from 'shared/hooks/countries';
import styles from './profileForm.module.scss';

const titleData = [
  { value: 'Mr.', label: 'Mr.', id: 'Mr.' },
  { value: 'Mrs.', label: 'Mrs.', id: 'Mrs.' },
  { value: 'Miss', label: 'Miss', id: 'Miss' },
  { value: 'Dr.', label: 'Dr.', id: 'Dr.' },
];

const Form = ({ country, state, setFieldValue, disabled }) => {
  const [countries, states] = useCountriesStates(country, state, setFieldValue);

  return (
    <div className={styles.profileForm}>
      <h1 className={styles.heading}>Profile</h1>
      <p className={styles.text}>
        Completing & saving your online profile will help auto-populate our
        multiple forms within the site. This may save you time and help your
        experience.
      </p>
      <div className={styles.formSection}>
        <h2 className={styles.subHeading}>Personal Information</h2>
        <div className="row">
          <div className={`col-sm-12`}>
            <div className="row">
              <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
                <Select
                  name="title"
                  data={titleData}
                  placeholder="Title"
                  ariaLabel="title"
                />
              </div>
            </div>
          </div>

          <div className={`col-sm-12`}>
            <div className="row">
              <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
                <TextInput name="firstName" placeholder="First Name&#42;" />
              </div>
              <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
                <TextInput name="lastName" placeholder="Last Name&#42;" />
              </div>
            </div>
            <div className="row">
              <div className={`${styles.datePicker} col-sm-12 col-md-6`}>
                <DatePicker
                  name="birthdate"
                  placeholderText="Date of Birth&#42;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.formSection}>
        <h2 className={styles.subHeading}>Phone Numbers</h2>
        <div className="row">
          <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
            <PhoneNumber
              name="mainPhone"
              placeholder="Phone&#42;"
              label="&#42;"
            />
          </div>
          <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
            <PhoneNumber name="mobilePhone" placeholder="Phone" />
          </div>
        </div>
      </div>
      <div className={styles.formSection}>
        <h2 className={styles.subHeading}>Address</h2>
        <div className={`row`}>
          <div className={`${styles.formSelect} col-md-12`}>
            <TextInput name="address1" placeholder="Address 1&#42;" />
          </div>
        </div>

        <div className="row">
          <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
            <TextInput name="address2" placeholder="Address 2" />
          </div>
          <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
            <TextInput name="city" placeholder="City&#42;" />
          </div>
        </div>

        <div className="row">
          <div className={`${styles.webViewZipCode} col-sm-12 col-md-6`}>
            <div className="row">
              <div className={`${styles.formSelect} col-6`}>
                <Select
                  name="state"
                  data={states}
                  placeholder="State&#42;"
                  ariaLabel="state"
                />
              </div>
              <div className={`${styles.formSelect} col-6`}>
                <TextInput name="zipcode" placeholder="Zipcode&#42;" />
              </div>
            </div>
          </div>
          <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
            <Select
              name="country"
              ariaLabel="country"
              data={countries}
              placeholder="Country&#42;"
              onChangeHandler={c => {
                setFieldValue('state', '');
              }}
            />
          </div>
        </div>
      </div>
      <Button
        disabled={disabled}
        type={`${styles.saveChange} app-primary-button`}
        btnType="submit"
      >
        Save Changes
      </Button>
      <hr className="mt-5 mb-4" />
    </div>
  );
};

export default Form;
