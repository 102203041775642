import { gql } from '@apollo/client';

export const GET_REGISTER_TO_BID_DATA = gql`
  query GetRegisterToBiData {
    profile {
      id
      firstName
      lastName
      birthdate
      email
      secondaryEmail
      mainPhone
      mobilePhone
      address1
      address2
      city
      state
      zipcode
      country
      financialDocumentSubmissionDate
      privacyPolicyAcceptanceDate
      buyerStatus
      company
      personalDocumentSubmissionDate
    }
  }
`;

export const REGISTER_TO_BID = gql`
  mutation RegisterToBid($data: RegisterToBidData) {
    RegisterToBid(data: $data) {
      code
    }
  }
`;

export const VERIFY_USER = gql`
  mutation verifyRegisterToBidData($verifiableData: VerifiableData!) {
    verifyRegisterToBidData(verifiableData: $verifiableData) {
      verificationStatus
    }
  }
`;

export const GET_AUCTION_LOTS = gql`
  query getAuctionVehicles($auctionIds: String) {
    getAuctionVehicles(auctionIds: $auctionIds) {
      objectID
      lotNumber
      auctionId
      title
      make
      model
      modelYear
      salesForceId
    }
  }
`;

export const KIOSK_REGISTER_TO_BID = gql`
  mutation KioskRegisterToBid(
    $data: RegisterToBidData
    $userSalesforceId: String
  ) {
    KioskRegisterToBid(data: $data, userSalesforceId: $userSalesforceId) {
      status
    }
  }
`;
export const ACTIVE_SIGHNED_TERMS_AND_CONDITIONS = gql`
  query checkActiveSignedTermsAndConditions($region: Region) {
    checkActiveSignedTermsAndConditions(region: $region)
  }
`;
